import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css";
import Footer from "../components/Footer";
import axios from "axios";
import { path, toRomanNumber } from "../consts";
import axiosRetry from "axios-retry";
import { getTableTypes, getDataForTable, serachPerson } from "../api";
import Results from "../components/OlympiadPage/Results";

axiosRetry(axios, { retries: 3 });

export default function ResultStats(props) {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const [tableTypesSecond, setTableTypesSecond] = useState(null);
  const [selectedOlympiadID, setSelectedOlympiadID] = useState("");
  const [tableData, setTableData] = useState(null);
  const [olympiadEditionList, setOlympiadEditionList] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${path}/competition/1001`)
        .then((res) => {
          setOlympiadEditionList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  async function handleChangeSecond(event) {
    let olympiadID = event.target.value;
    setSelectedOlympiadID(olympiadID);
    setIsLoading(true);
    if (olympiadID !== "") {
      const fetchData = async () => {
        axios
          .get(`${path}/statistics/results/${olympiadID}`)
          .then((res) => {
            setTableData(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      };
      fetchData();
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: { bmd: 10, xs: 7 } }}
          >
            Kącik Statystyczny
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={"h5"}
            sx={{ mt: 2, mb: 5 }}
          >
            Wyniki Olimpiad
          </Typography>
          <Box
            margin='auto'
            maxWidth="200px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl fullWidth>
              <InputLabel id="select-label">Edycja Olimpiady</InputLabel>
              <Select
                labelId="select-label"
                value={selectedOlympiadID}
                label="Rodzaj statystyk"
                onChange={handleChangeSecond}
              >
                {olympiadEditionList.map((olympiad) => (
                  <MenuItem key={olympiad.id} value={olympiad.id}>{`${
                    toRomanNumber[olympiad.edition_number]
                  } OG`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {tableData && (
            <Box
              sx={{
                ml: 4,
                mr: 4,
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Results results={tableData} isStartListExpanded></Results>
            </Box>
          )}
          <Footer />
        </div>
      )}
    </div>
  );
}
