import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import { Box, InputLabel, MenuItem, Select, Tooltip, Typography, useMediaQuery } from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css"
import Footer from "../components/Footer";
import axios from "axios";
import { path, toRomanNumber } from "../consts";
import axiosRetry from 'axios-retry';
import { getTableTypes, getDataForTable, serachPerson } from "../api";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";
import { useParams } from "react-router";
import SchoolIcon from '@mui/icons-material/School';
import { School } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

axiosRetry(axios, { retries: 3 });

export default function PersonalPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [personalData, setPersonalData] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
    let { PersonId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            axios
              .get(`${path}/statistics/person/${PersonId}`)
              .then((res) => {
                console.log(res.data)
                setPersonalData(res.data);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err.message);
              });
          };
        fetchData()
      }, []);

    

    return(
        <div>
        {isLoading ? (
            <Loader />
          ) : 
            <div>
              <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
                <ToolBar></ToolBar>
              </Box>
              <PageHeader />
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={isMobile ? "h4" : "h2"}
                sx={{ mt: {bmd: 10, xs: 7} }}
              >
                Kącik Statystyczny
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={"h5"}
                sx={{ mt: 2, mb: 5}}
              >
                Informacje osobowe
              </Typography>
              <Box sx={{ml: 5}}>
              <Typography variant="h3" sx={{mb: 4}}>
                {personalData.person_versions.title} {personalData.person_versions.first_name}{" "}
                {personalData.person_versions.surname}
                {personalData.old_names.length !== 0 && <Typography>({personalData.old_names[0]})</Typography>}
              </Typography>
              <Typography sx={{mb: 6}}>

                {personalData.student_achievements.map((achievement) => {
                    if (achievement.name == 'Laureat') {
                  return <Tooltip title={`${toRomanNumber[achievement.edition_number]} OG, ${achievement.school_year}`}>
                    <EmojiEventsIcon sx={{fontSize:50, color: 'gold'}}/>
                  </Tooltip>}
                  else {
                    return <Tooltip title={`${toRomanNumber[achievement.edition_number]} OG, ${achievement.school_year}`}>
                    <StarIcon sx={{fontSize:50, color: 'yellow'}}/>
                </Tooltip>
                  }})}
                  {personalData.teacher_achievements.map((achievement) => {
                    if (achievement.name == 'Laureat') {
                  return <Tooltip title={`${achievement.first_name} ${achievement.surname} - ${toRomanNumber[achievement.edition_number]} OG, ${achievement.school_year}`}>
                    <SchoolIcon sx={{fontSize:50, color: 'gold'}}/>
                  </Tooltip>}
                  else {
                    return <Tooltip title={`${achievement.first_name} ${achievement.surname} - ${toRomanNumber[achievement.edition_number]} OG, ${achievement.school_year}`}>
                    <SchoolIcon sx={{fontSize:50, color: 'yellow'}}/>
                </Tooltip>
                  }
                })}
                <Tooltip sx={{ml:5}}  title={
                  <Typography>
                    <StarIcon sx={{color: 'yellow'}}/> - finalista OG <br/>
                    <EmojiEventsIcon sx={{color: 'gold'}}/> - laureat OG<br/>
                    <SchoolIcon sx={{color: 'yellow'}}/> - nauczyciel finalisty OG<br/>
                    <SchoolIcon sx={{color: 'gold'}}/> - nauczyciel laureata OG

                  </Typography>
                }>
                  <HelpOutlineIcon/>

                </Tooltip>

              </Typography>
              {personalData.student_achievements.length > 0 && 
              <div>
              <Typography variant='h4'  sx={{mb: 2}}>Wyniki - uczeń: </Typography>

              {personalData.student_achievements.map((achievement) => {
                    if (achievement.name == 'Laureat') {
                  return                 <Typography sx={{mb: 4}}>
                  Laureat {toRomanNumber[achievement.edition_number]} OG - {achievement.school_year}
                </Typography>}
                  else {
                    return <Typography sx={{mb: 4}}>
                  Finalista {toRomanNumber[achievement.edition_number]} OG - {achievement.school_year}
                </Typography>
                  }
                })}</div>}
            {personalData.teacher_achievements.length > 0 && 
            <div>
            <Typography variant='h4'  sx={{mb: 2}}>Wyniki - nauczyciel: </Typography>

                {personalData.teacher_achievements.map((achievement) => {
                    if (achievement.name == 'Laureat') {
                    return                 <Typography sx={{mb: 4}}>
                    Nauczyciel laureata: {achievement.first_name} {achievement.surname} {toRomanNumber[achievement.edition_number]} OG - {achievement.school_year}
                </Typography>}
                    else {
                    return <Typography sx={{mb: 4}}>
                    Nauczyciel finalisty: {achievement.first_name} {achievement.surname} {toRomanNumber[achievement.edition_number]} OG - {achievement.school_year}
                </Typography>
            
    } 
  })}</div>}

                
            </Box>
              <Footer/>
            </div>

    }</div>)
    
}