import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  FormControl,
  TextField,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css";
import Footer from "../components/Footer";
import axios from "axios";
import { path } from "../consts";
import axiosRetry from "axios-retry";
import { useNavigate } from "react-router";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';


axiosRetry(axios, { retries: 3 });

export default function PeopleStats(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const [selectedType, setSelectedType] = useState("");
  const [tableData, setTableData] = useState(null);
  const [sortingIndex, setSortingIndex] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [serachPhrase, setSearchPhrase] = useState("");
  const [peopleList, setPeopleList] = useState(null)


  useEffect(() => {
    if (serachPhrase !== ''){
      const delayDebounceFn = setTimeout(() => {
        axios
          .get(`${path}/search/person/${serachPhrase}`)
          .then((res) => {
            setPeopleList(res.data.results);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    else {
      setPeopleList(null)
    }
  }, [serachPhrase]);

  const statisticsOptions = [
    {
      name: "Szukaj osoby",
      id: 1,
    },
    {
      name: "Lista uczniów",
      id: 2,
    },
    {
      name: "Lista nauczycieli",
      id: 3,
    },
    // {
    //     name: 'Lista działaczy',
    //     id: 4
    // }
  ];

  async function handleChangeSecond(event) {
    let selectedType = event.target.value;
    setIsLoading(true);
    setSelectedType(selectedType);
    if (selectedType !== "") {
      const fetchData = async () => {
        axios
          .get(`${path}/statistics/people/1001?variant=${selectedType}`)
          .then((res) => {
            setTableData(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      };
      fetchData();
    }
  }

  var headers = [
    {
      level: 0,
      items: [
        { title: "Imię", prop: "first_name", link: "id" },
        { title: "Nazwisko", prop: "surname", link: "id" },
        !isMobile ? { title: "Edycje Olimpiady", prop: "editions", link: "idv" } : {},
        { title: "L", prop: "L_number", link: "id" },
        { title: "F", prop: "F_number", link: "id" },
      ],
    },
  ];

  function handleSort(sortBy) {
    if (sortingIndex !== sortBy) {
      setSortingIndex(sortBy);
      setIsAscending(true);
    } else setIsAscending((prev) => !prev);
    setTableData((prevTableData) =>
      prevTableData.toSorted((a, b) => {
        let first_element = !isNaN(parseInt(String(a[sortBy]).split(",")[0]))
          ? parseInt(String(a[sortBy]).split(",")[0])
          : a[sortBy];
        let second_element = !isNaN(parseInt(String(b[sortBy]).split(",")[0]))
          ? parseInt(String(b[sortBy]).split(",")[0])
          : b[sortBy];
        return isAscending
          ? first_element > second_element
          : first_element < second_element;
      })
    );
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: { bmd: 10, xs: 7 } }}
          >
            Kącik Statystyczny
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={"h5"}
            sx={{ mt: 2, mb: 5 }}
          >
            Ludzie Olimpiady
          </Typography>
          <Box
            margin='auto'
            maxWidth="300px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl fullWidth>
              <InputLabel id="select-label">Rodzaj statystyk</InputLabel>
              <Select
                labelId="select-label"
                value={selectedType}
                label="Rodzaj statystyk"
                onChange={handleChangeSecond}
              >
                {statisticsOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {selectedType === 1 && (
            <Grid container sx={{mt: 6}}>
              <Grid item xs={12} sx={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
              <TextField label='Wyszukaj' sx={{width: '50%'}}                 
              onChange={(e) => {
                  setSearchPhrase(e.target.value);
                }}/>
              <Tooltip title="Wyszukiwanie możliwe jest po numerze olimpiady (cyfry rzymskie), miejscowości finałów, nazwisku uczestnika">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
              </Grid>
              <Grid item xs={12} sx={{display: 'flex', alignItems:'center', justifyContent: 'center', mr:5}}>
                {peopleList && peopleList.length !== 0 &&
                  <List sx={{width: '50%',  bgcolor: 'background.paper',  boxShadow: '0px 2px 5px grey',  borderRadius: '0 0 5px 5px', p:0}}>
                    {peopleList.map(person => 
                    <>
                      <ListItem className="search-result" sx={{py: 2}}
                      onClick={() => navigate(`./${person.PersonVersion.person_id}`)}>
                        {person.PersonVersion.first_name} {person.PersonVersion.surname}
                        
                      </ListItem>
                      <Divider/>
                      </>
                    )}
                  </List>}
              </Grid>
            </Grid>
          )}
          {tableData && (
            <Box
              sx={{
                ml: 4,
                mr: 4,
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <table className="styled-table">
                <thead>
                  {headers.map((level) => {
                    return (
                      <tr>
                        {level.items.map((column) => (
                          <th onClick={() => handleSort(column.prop)}>
                            {column.title}
                          </th>
                        ))}
                      </tr>
                    );
                  })}
                </thead>
                <tbody>
                  {tableData.map((item, index) => {
                    return (
                      <tr className={"expanded"}>
                        {headers[headers.length - 1].items.map((column) => (
                          <th
                            onClick={() => {
                              if (column.link) {
                                navigate(
                                  `/statystyki/osoby/${item[column.link]}`
                                );
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            {item[column.prop]}
                          </th>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          )}
          <Footer />
        </div>
      )}
    </div>
  );
}
