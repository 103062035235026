import {
  olympiadList,
  artifactsList,
  startPageContent,
  importantPeople,
  olympiadEdition1,
  artifactsTypes,
  olympiadEditionList,
  tableTypes,
  scoresTable,
  studentIndexes,
  serachResult,
} from "./mockData";

export const getOlympiadList = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(olympiadList), 250);
  });

export const getArtifactsList = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(artifactsList), 250);
  });

export const getStartPageContent = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(startPageContent), 250);
  });

export const getImportantPeople = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(importantPeople), 250);
  });

export const getOlympiad1 = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(olympiadEdition1), 250);
  });

export const getArtifactsTypes = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(artifactsTypes), 250);
  });

export const getOlympiadEditionList = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(olympiadEditionList), 250);
  });

export const getTableTypes = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(tableTypes.firstLevel), 250);
  });

export function getDataForTable(type) {
  if (type === "I-OG")
    return new Promise((resolve) => {
      setTimeout(() => resolve(scoresTable), 250);
    });
  else if (type === "students")
    return new Promise((resolve) => {
      setTimeout(() => resolve(studentIndexes), 250);
    });
  else if (type === "scores")
    return new Promise((resolve) => {
      setTimeout(() => resolve(tableTypes.secondLevelScores), 250);
    });
  else if (type === "indexes")
    return new Promise((resolve) => {
      setTimeout(() => resolve(tableTypes.secondLevelIndexes), 250);
    });
  else if (type === "schools")
    return new Promise((resolve) => {
      setTimeout(() => resolve(tableTypes.secondLevelSchools), 250);
    });
  else if (type === "organizers")
    return new Promise((resolve) => {
      setTimeout(() => resolve(tableTypes.secondLevelOrganizers), 250);
    });
}

export const serachPerson = () =>
  new Promise((resolve) => {
    setTimeout(() => resolve(serachResult), 250);
  });
