import { Typography, Box, useMediaQuery, Button } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import "../../pages/Table.css";
import { useNavigate } from "react-router";


var headersDesktop = [
    {
      level: 1,
      items: [
        { title: "", colspan: 4 },
        { title: "Szkoła", colspan: 2 },
        { title: "Nauczyciel", colspan: 2 },
      ],
    },
    {
      level: 0,
      items: [
        { title: "Tytuł", prop: "title", link: false },
        { title: "Imię", prop: "student_name", link: "student_id"  },
        { title: "Nazwisko", prop: "student_surname", link: "student_id" },
        { title: "Klasa", prop: "school_class", link: false  },
        { title: "Nazwa", prop: "school_name", link: false  },
        { title: "Miejscowość", prop: "city", link: false},
        { title: "Imię", prop: "teacher_name", link: false},
        { title: "Nazwisko", prop: "teacher_surname", link: false },
      ],
    },
]

var headersMobile = [
    {
      level: 0,
      items: [
        { title: "Tytuł", prop: "title" },
        { title: "Imię", prop: "student_name" },
        { title: "Nazwisko", prop: "student_surname" },
      ]
    },
]

export default function Results(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  let headers = isSmallScreen ? headersMobile : headersDesktop

  let isStartListExpanded = (props.isStartListExpanded === true) ? true : false

  const [isListExpanded, setIsListExpanded] = useState(isStartListExpanded)

  return (
    <Box sx={{display: 'flex', flexDirection:'column', justifyContent: 'center' }} >
        <Typography variant="h4" sx={{ mb: 2 }} id='results-top'>
          Wyniki zawodów finałowych
        </Typography>
        <Typography sx={{ mb: 2, display: {xs:'flex', md: 'none'} }}>
          Aby zobaczyć więcej szczegółów o uczestnikach spróbuj wyświetlić tabelę na większym ekranie
        </Typography>
        <table className="styled-table">
        <thead>
          {headers.map((level) => {
            if (level.level !== 0)
              return (
                <tr>
                  <th></th>
                  {level.items.map((column) => (
                    <th colSpan={column.colspan}>{column.title}</th>
                  ))}
                </tr>
              );
            else
              return (
                <tr>
                  <th>L.p.</th>
                  {level.items.map((column) => (
                    <th>{column.title}</th>
                  ))}
                </tr>
              );
          })}
        </thead>
        <tbody>
          {props.results.slice(0, isListExpanded ? props.results.length : 5).map((item, index) => {
            return (
                <tr className={isListExpanded? "expanded" : null}>
                  <th>{index + 1}</th>
                  {headers[
                    headers.length - 1
                  ].items.map((column) => (
                    <th onClick={() => {
                      if (column.link){ 
                      navigate(`/statystyki/osoby/${item[column.link]}`);
                      window.scrollTo(0, 0)};
                    }}>{item[column.prop]}</th>
                  ))}
                </tr>
              );
          })}
            {!isListExpanded &&
                <tr className="last-row-but-one-not-expanded">
                  {<th>6</th>}
                  {headers[
                    headers.length - 1
                  ].items.map((column) => (
                    <th>{props.results[5][column.prop]}</th>
                  ))}
                </tr>
            }
            {!isListExpanded &&
                <tr className="last-row-not-expanded">
                  {<th>7</th>}
                  {headers[
                    headers.length - 1
                  ].items.map((column) => (
                    <th>{props.results[6][column.prop]}</th>
                  ))}
                </tr>
            }
        </tbody>
        </table>
        <Button onClick={(e) => setIsListExpanded((prevState) => {
          if (prevState){
            const anchor = (e.target.ownerDocument || document
              ).querySelector('#results-top');

              if (anchor) {
                anchor.scrollIntoView({
                  block: 'start',
                });
              }
          }
          return !prevState
        }
        )} variant='outlined'>
           {isListExpanded ? "Pokaż mniej" : "Pokaż więcej"}
        </Button>
    </Box>
  );
}
