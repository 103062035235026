import { useState } from "react";
import Loader from "../components/Loader";
import { Box, Typography, useMediaQuery } from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css"
import Footer from "../components/Footer";


export default function MapStats(props) {

    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));

    return(
        <div>
        {isLoading ? (
            <Loader />
          ) : 
            <div>
              <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
                <ToolBar></ToolBar>
              </Box>
              <PageHeader />
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={isMobile ? "h4" : "h2"}
                sx={{ mt: {bmd: 10, xs: 7} }}
              >
                Kącik Statystyczny
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={"h5"}
                sx={{ mt: 2, mb: 5}}
              >
                Interaktywne mapy ze statystykami
              </Typography>
               <div class="embed-container">
                <iframe frameborder="0" title="mapa_test" src="https://olimpiadageo.maps.arcgis.com/apps/dashboards/b71062e5ae694f38aa04cd0c1c042b51"></iframe></div>
                <Footer/>
               </div>

    }</div>)
    
}