import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import HomePage from "./pages/HomePage";
import OlympiadPage from "./pages/OlympiadPage";
import OlympiadList from "./pages/OlympiadList";
import Error404 from "./pages/404";
import History from "./pages/History";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./consts";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ArtifactsList from "./pages/ArtifactsList";
import People from "./pages/People";
import ScrollTop from "./components/OlympiadPage/ScrollTop";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Footer from "./components/Footer";
import Statistics from "./pages/Statistics";
import SchoolStats from "./pages/SchoolStats"
import PeopleStats from "./pages/PeopleStats"
import ResultStats from "./pages/ResultStats"
import MapStats from "./pages/MapStats"
import PersonalPage from "./pages/PersonalPage";
import Posters from "./pages/Posters";
import PostersKG from "./pages/PostersKG";
import PostersKO from "./pages/PostersKO";
import SchoolPage from "./pages/SchoolPage";


const router = createHashRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/historia",
    element: <History />,
  },
  {
    path: "/olimpiada/:OlympiadId",
    element: <OlympiadPage />,
  },
  {
    path: "/olimpiada",
    element: <OlympiadList />,
  },
  {
    path: "/pamiatki",
    element: <ArtifactsList />,
  },
  {
    path: "/ludzie",
    element: <People />,
  },
  {
    path: "/statystyki",
    element: <Statistics />,
  },
  {
    path: "/statystyki/osoby",
    element: <PeopleStats />,
  },
  {
    path: "/statystyki/wyniki",
    element: <ResultStats />,
  },
  {
    path: "/statystyki/szkoly",
    element: <SchoolStats />,
  },
  {
    path: "/statystyki/szkoly/:SchoolId",
    element: <SchoolPage />,
  },
  {
    path: "/statystyki/mapy",
    element: <MapStats />,
  },
  {
    path: "/statystyki/osoby/:PersonId",
    element: <PersonalPage />,
  },
  {
    path: "/postery",
    element: <Posters />,
  },
  {
    path: "/postery/KG",
    element: <PostersKG />,
  },
  {
    path: "/postery/KO",
    element: <PostersKO />,
  },
  {
    path: "/postery/KO/:Commitee",
    element: <Posters />,
  },
  {
    path: "/*",
    element: <Error404 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <div id="back-to-top-anchor"></div>
      <RouterProvider router={router} />
      <ScrollTop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
