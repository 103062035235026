import { ToolBar } from "../components/Toolbar";
import { Box, FormControl, TextField, Typography, Grid } from "@mui/material";
import PageHeader from "../components/PageHeader";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getTableTypes, getDataForTable, serachPerson } from "../api";
import "./Table.css";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";
import AnimatedCard from "../components/AnimatedCard";
import Loader from "../components/Loader";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@emotion/react";
import "./App.css";
import Footer from "../components/Footer";

const statisticTypes = [
  // {
  //   id: 1,
  //   name: "Postery Komitetów Okręgowych",
  //   photo: "example.jpg",
  //   path: "KO",
  // },
  {
    id: 2,
    name: "Postery Komitetu Głównego",
    photo: "/posters/images/historia_poster.png",
    path: "KG",
  },
];

function Posters(props) {
  const [tableTypes, setTableTypes] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedTypeFirst, setSelectedTypeFirst] = useState(null);
  const [selectedTypeSecond, setSelectedTypeSecond] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [serachResult, setSearchResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [tableTypesSecond, setTableTypesSecond] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));

  useEffect(() => {
    const fetchData = async () => {
      const tableTypes = await getTableTypes();
      setTableTypes(tableTypes);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm !== null) {
        const searchResult = await serachPerson();
        setSearchResult(searchResult);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  async function handleChangeFirst(event) {
    setTableTypesSecond(null);
    setTableData(null);
    setSearchTerm(null);
    setSearchResult(null);
    setSelectedTypeFirst(event.target.value);
    const data = await getDataForTable(event.target.value);
    if (Array.isArray(data)) setTableTypesSecond(data);
    else setTableData(data);
  }

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <div >
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7} }}
          >
            Postery 50-lecia
          </Typography> 
           <Box sx={{display: 'flex', justifyContent: 'center', px: 5}}>
            <Grid container spacing={5} sx={{ p: 3 }}>
              {statisticTypes.map((type) => (
              <Grid item xs={12} md={6} key={type.id}>
                <AnimatedCard id={type.id} name={type.name} image={type.photo} path={type.path} />
                </Grid>))}
              </Grid>
          </Box>
          <Footer/>
        </div>
      )}
    </div>
  );
}

export default Posters;
