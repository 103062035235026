import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import { Box, Button, Grid, InputLabel, MenuItem, Select, Tooltip, Typography, useMediaQuery } from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css"
import Footer from "../components/Footer";
import axios from "axios";
import { path, toRomanNumber } from "../consts";
import axiosRetry from 'axios-retry';
import { getTableTypes, getDataForTable, serachPerson } from "../api";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";
import { useParams } from "react-router";
import SchoolIcon from '@mui/icons-material/School';
import FinalStageMap from "../components/OlympiadPage/FinalStageMap";
import { useNavigate } from "react-router";

axiosRetry(axios, { retries: 3 });

export default function SchoolPage(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [schoolData, setSchoolData] = useState(null);
    const [showLDetails, setSchowLDetails] = useState(false);
    const [showFDetails, setSchowFDetails] = useState(false);
    const [showODetails, setSchowODetails] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate()

    const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
    let { SchoolId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            axios
              .get(`${path}/statistics/school/${SchoolId}`)
              .then((res) => {
                console.log(res.data)
                setSchoolData(res.data);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err.message);
              });
          };
        fetchData()
      }, [SchoolId]);

    

    return(
        <div>
        {isLoading ? (
            <Loader />
          ) : 
            <div>
              <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
                <ToolBar></ToolBar>
              </Box>
              <PageHeader />
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={isMobile ? "h4" : "h2"}
                sx={{ mt: {bmd: 10, xs: 7} }}
              >
                Kącik Statystyczny
              </Typography>
              <Typography
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                variant={"h5"}
                sx={{ mt: 2, mb: 5}}
              >
                Informacje o szkołach   
              </Typography>
              <Grid container sx={{ml: 5, width: '90vw'}}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" >
                    {schoolData.newest_school_version.name}
                  </Typography>
                  <Typography variant="h5" sx={{mb: 4}} >
                    {schoolData.address.city}
                  </Typography>
                  {schoolData.previous_names.length !== 0 && 
                  <Typography variant="h5" sx={{mb: 2}}>
                      Dawniej: {schoolData.previous_names.map((prevName) => (
                        <Typography variant="h6" sx={{mt: 1}}>
                          {prevName} <br/>
                        </Typography>
                      ))}
                  </Typography>
                  }

                  <Typography variant="h5" sx={{mt: 5}}>
                    Liczba laureatów <EmojiEventsIcon sx={{color: 'gold'}}/> :  {schoolData.laureate.length}
                  </Typography>
                  {showLDetails && schoolData.laureate.map(person => 
                    <Typography sx={{fontSize: '20', mt: 2, cursor: 'pointer'}} onClick={() => (navigate(`/statystyki/osoby/${person.id_1}`))}>
                      {person.first_name} {person.surname} ({person.edition_number} OG)
                    </Typography>
                  )}
                  <Button sx={{color: 'black', mt:1}} onClick={() => setSchowLDetails(!showLDetails)}>
                    {showLDetails ? 'Schowaj szczegóły': 'Pokaż szczegóły...'}
                  </Button >
                  <Typography variant="h5" sx={{mt: 5}}>
                    Liczba finalistów <StarIcon sx={{color: 'yellow'}}/>: {schoolData.finalists.length}
                  </Typography> 
                  {showFDetails && schoolData.finalists.map(person => 
                    <Typography sx={{fontSize: '20', mt: 2, cursor: 'pointer'}} onClick={() => (navigate(`/statystyki/osoby/${person.id_1}`))}>
                      {person.first_name} {person.surname} ({person.edition_number} OG)
                    </Typography>
                  )}
                  <Button sx={{color: 'black', mt:1}} onClick={() => setSchowFDetails(!showFDetails)}>
                    {showFDetails ? 'Schowaj szczegóły': 'Pokaż szczegóły...'}
                  </Button >
                  <Typography variant="h5" sx={{mt: 5}}>
                    Liczba zorganizowanych finałów: {schoolData.edition_organizer.length}
                  </Typography>
                  {showODetails && schoolData.edition_organizer.map(competition => 
                    <Typography sx={{fontSize: '20', mt: 2, cursor: 'pointer'}} onClick={() => (navigate(`/olimpiada/${competition.Competition.id}`))}>
                      {competition.Competition.edition_number} OG
                    </Typography>
                  )}
                  <Button sx={{color: 'black', mt:1}} onClick={() => setSchowODetails(!showODetails)}>
                    {showODetails ? 'Schowaj szczegóły': 'Pokaż szczegóły...'}
                  </Button >
                </Grid>
                <Grid item xs={12} md={6}>
                  <FinalStageMap hostSchoolAddresses={[schoolData.address]} scale='city' 
                  hostSchools={[schoolData.newest_school_version]} otherPoints={[]}/>
                </Grid>
              </Grid>

                
              <Footer/>
            </div>

    }</div>)
    
}