export const olympiadList = [
  {
    id: 1,
    editionNumber: 1,
    schoolYearFirst: 1974,
    schoolYearSecond: 1975,
    place: "Łódź",
    img: "/zdjecie.png",
  },
  {
    id: 2,
    editionNumber: 2,
    schoolYearFirst: 1975,
    schoolYearSecond: 1976,
    place: "Nowy Sącz",
    img: "/zdjecie.png",
  },
  {
    id: 3,
    editionNumber: 3,
    schoolYearFirst: 1976,
    schoolYearSecond: 1977,
    place: "Płock",
    img: "/zdjecie.png",
  },
  {
    id: 4,
    editionNumber: 4,
    schoolYearFirst: 1977,
    schoolYearSecond: 1978,
    place: "Olsztyn",
    img: "/zdjecie.png",
  },
  {
    id: 5,
    editionNumber: 5,
    schoolYearFirst: 1978,
    schoolYearSecond: 1979,
    place: "Piotrków Trybunalski",
    img: "/zdjecie.png",
  },
  {
    id: 6,
    editionNumber: 6,
    schoolYearFirst: 1979,
    schoolYearSecond: 1980,
    place: "Przemyśl",
    img: "/zdjecie.png",
  },
  {
    id: 7,
    editionNumber: 7,
    schoolYearFirst: 1980,
    schoolYearSecond: 1981,
    place: "Katowice",
    img: "/zdjecie.png",
  },
  {
    id: 8,
    editionNumber: 8,
    schoolYearFirst: 1981,
    schoolYearSecond: 1982,
    place: "Koszalin",
    img: "/zdjecie.png",
  },
  {
    id: 9,
    editionNumber: 9,
    schoolYearFirst: 1982,
    schoolYearSecond: 1983,
    place: "Białystok",
    img: "/zdjecie.png",
  },
  {
    id: 10,
    editionNumber: 10,
    schoolYearFirst: 1983,
    schoolYearSecond: 1984,
    place: "Jadwisin k/Warszawy",
    img: "/zdjecie.png",
  },
  {
    id: 11,
    editionNumber: 11,
    schoolYearFirst: 1984,
    schoolYearSecond: 1985,
    place: "Kłodzko",
    img: "/zdjecie.png",
  },
  {
    id: 12,
    editionNumber: 12,
    schoolYearFirst: 1985,
    schoolYearSecond: 1986,
    place: "Zamość",
    img: "/zdjecie.png",
  },
  {
    id: 13,
    editionNumber: 13,
    schoolYearFirst: 1986,
    schoolYearSecond: 1987,
    place: "Kiekrz k/Poznania",
    img: "/zdjecie.png",
  },
  {
    id: 14,
    editionNumber: 14,
    schoolYearFirst: 1987,
    schoolYearSecond: 1988,
    place: "Toruń",
    img: "/zdjecie.png",
  },
  {
    id: 15,
    editionNumber: 15,
    schoolYearFirst: 1988,
    schoolYearSecond: 1989,
    place: "Gdynia",
    img: "/zdjecie.png",
  },
];

export const artifactsList = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Plakat I OG",
    olympiadEdition: 1,
    category: "poster",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Przypinka I OG",
    olympiadEdition: 1,
    category: "pin",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Koszulka I OG",
    olympiadEdition: 1,
    category: "shirt",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Medal I OG",
    olympiadEdition: 1,
    category: "medal",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Plakat II OG",
    olympiadEdition: 2,
    category: "poster",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Przypinka II OG",
    olympiadEdition: 2,
    category: "pin",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Przypinka III OG",
    olympiadEdition: 3,
    category: "pin",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Koszulka V OG",
    olympiadEdition: 5,
    category: "shirt",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Przypinka VIII OG",
    olympiadEdition: 8,
    category: "pin",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Pamiątkowy zegar",
    olympiadEdition: 10,
    category: "other",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Tarcza od XII LO w Chorzowie",
    olympiadEdition: 19,
    category: "other",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Plakat XXXI OG",
    olympiadEdition: 21,
    category: "poster",
  },
];

export const startPageContent = {
  olympiadsOfTheWeek: [
    {
      editionNumber: 1,
      schoolYearFirst: 1974,
      schoolYearSecond: 1975,
      place: "Łódź",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit",
      img: "/zdjecie.png",
      id: 1,
    },
    {
      editionNumber: 2,
      schoolYearFirst: 1975,
      schoolYearSecond: 1976,
      place: "Nowy Sącz",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit",
      img: "/zdjecie.png",
      id: 2,
    },
  ],
  selectedOlympiads: [
    {
      id: 6,
      editionNumber: 6,
      schoolYearFirst: 1979,
      schoolYearSecond: 1980,
      place: "Przemyśl",
      img: "/zdjecie.png",
    },
    {
      id: 15,
      editionNumber: 25,
      schoolYearFirst: 1988,
      schoolYearSecond: 1989,
      place: "Gdynia",
      img: "/zdjecie.png",
    },
    {
      id: 33,
      editionNumber: 33,
      schoolYearFirst: 2006,
      schoolYearSecond: 2007,
      place: "Łańcut",
      img: "/zdjecie.png",
    },
    {
      id: 45,
      editionNumber: 45,
      schoolYearFirst: 2018,
      schoolYearSecond: 2019,
      place: "Kołobrzeg",
      img: "/zdjecie.png",
    },
  ],
  selectedArtifacts: [
    {
      name: "pin",
      text: "Przypinki",
      img: "/zdjecie.png",
    },
    {
      name: "shirt",
      text: "Koszulki",
      img: "/zdjecie.png",
    },
    {
      name: "poster",
      text: "Plakaty",
      img: "/zdjecie.png",
    },
  ],
};

export const importantPeople = [
  {
    id: 1,
    title: "prof.",
    name: "Anna",
    surname: "Dylikowa",
    shortDescription:
      "Inicjatorka Olimpiady Geograficznej i długoletnia przewodnicząca Komitetu Głównego OG",
    biogram:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit",
    photo: "/zdjecie1.jpg",
  },
  {
    id: 2,
    title: "prof.",
    name: "Anna",
    surname: "Dylikowa",
    shortDescription:
      "Inicjatorka Olimpiady Geograficznej i długoletnia przewodnicząca Komitetu Głównego OG",
    biogram:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit",
    photo: "/zdjecie1.jpg",
  },
  {
    id: 3,
    title: "prof.",
    name: "Anna",
    surname: "Dylikowa",
    shortDescription:
      "Inicjatorka Olimpiady Geograficznej i długoletnia przewodnicząca Komitetu Głównego OG",
    biogram:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit",
    photo: "/zdjecie1.jpg",
  },
];

export const olympiadEdition1 = {
  editionNumber: 1,
  schoolYearFirst: 1974,
  schoolYearSecond: 1975,
  place: "Łódź",
  img: "/zdjecie.png",
  motto: "Poznaj świat, abyś mógł lepiej służyć własnemu krajowi",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat",
  firstStageTopics: [
    {
      letter: "A",
      topic:
        "Przedstawienie zmian zachodzących w środowisku pod wpływem działalności człowieka, na podstawie własnych obserwacji i wywiadów",
    },
    {
      letter: "B",
      topic:
        "Opracowanie projektu kilkudniowej wycieczki pieszej, rowerowej lub kajakowej po własnym województwie na podstawie własnej znajomości terenu oraz odpowiednio dobranej literatury",
    },
    {
      letter: "C",
      topic: "Przekróje przez Polskę",
    },
  ],
  dateFinalsStart: new Date("1975-04-28"),
  dateFinalsEnd: new Date("1975-05-01"),
  hostSchool: {
    name: "I Liceum Ogólnokształcące im. Mikołaja Kopernika",
    position: [51.78196481138299, 19.445857231185256],
  },
  overnightPlace: {
    name: " Internat Zespołu szkół nr 1",
    position: [51.77195481138299, 19.445817231185256],
  },
  otherPoints: [
    { name: "Stołówka", position: [51.78195481138299, 19.455817231185256] },
  ],
  nOfParticiapants: 78,
  winner: {
    name: "Jan",
    surname: "Bach",
    school: "I LO im. Mikołaja Koperika",
    schoolPlace: "Toruń",
    teacher: {
      title: "mgr",
      name: "Jan",
      surname: "Kowalski",
    },
  },
  quotations: [
    {
      text: "reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria",
      author: {
        name: "Jan",
        surname: "Kowalski",
        role: "laureat",
      },
    },
    {
      text: "quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria",
      author: {
        name: "Marian",
        surname: "Wiśniewski",
        role: "wolonatriusz",
      },
    },
    {
      text: "Excepteur sint occaecat cupidatat ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla paria",
      author: {
        name: "Anna",
        surname: "Kwiatkowska",
        role: "finalista",
      },
    },
  ],
  artifacts: [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Plakat I OG",
      olympiadEdition: 1,
      category: "poster",
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Przypinka I OG",
      olympiadEdition: 1,
      category: "pin",
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Koszulka I OG",
      olympiadEdition: 1,
      category: "shirt",
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Medal I OG",
      olympiadEdition: 1,
      category: "medal",
    },
  ],
  interestingFacts: [
    "Była to pierwsza w historii edycja Olimpiady Geograficznej",
    "Główną inicjatorką powstania Olimpiady była prof. Anna Dylikowa",
    "Liczba uczestników, która zgłosiła chęć udziału w pierwszej edycji przekroczyła 1000 osób",
  ],

  chartData: [],

  galleryPhotos: [
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
    { img: "/zdjecie.png", description: "plplaplapl" },
  ],

  questions: [
    {
      id: 0,
      img: null,
      text: null,
      questions: [
        {
          type: "choice",
          variant: "single",
          id: 1,
          questionText: "Najwyższy szczyt Polski to:",
          answers: [
            { id: 0, text: "Tatry" },
            { id: 1, text: "Babia Góra" },
            { id: 2, text: "Rysy" },
            { id: 3, text: "Śnieżka" },
          ],
        },
      ],
    },
    {
      id: 1,
      img: null,
      text: null,
      questions: [
        {
          type: "choice",
          variant: "multi",
          id: 2,
          questionText: "Wybierz wszystkie miasta w których jeżdżą tramwaje",
          answers: [
            { text: "Poznań", id: 4 },
            { text: "Toruń", id: 5 },
            { text: "Lublin", id: 6 },
            { text: "Szczecin", id: 7 },
            { text: "Rzeszów", id: 8 },
            { text: "Warszawa", id: 9 },
          ],
        },
      ],
    },
    {
      id: 2,
      img: null,
      text: null,
      questions: [
        {
          type: "match",
          variant: "multi",
          id: 3,
          questionText: "Dopasuj stolicę do województwa:",
          groups: [
            { id: 10, text: "Mazowieckie" },
            { id: 11, text: "Podkarpackie" },
            { id: 12, text: "Kujawsko-Pomorskie" },
          ],
          answers: [
            { id: 13, text: "Toruń" },
            { id: 14, text: "Rzeszów" },
            { id: 15, text: "Warszawa" },
            { id: 16, text: "Bydgoszcz" },
            { id: 17, text: "Gdańsk" },
          ],
        },
      ],
    },
    {
      id: 3,
      img: null,
      text: null,
      questions: [
        {
          type: "match",
          variant: "single",
          id: 4,
          questionText: "Dopasuj okres do ery",
          groups: [
            { id: 10, text: "Paleozoik" },
            { id: 11, text: "Mezozoik" },
            { id: 12, text: "Kenozoik" },
          ],
          answers: [
            { id: 13, text: "Sylur" },
            { id: 14, text: "Trias" },
            { id: 15, text: "Kambr" },
            { id: 16, text: "Perm" },
            { id: 17, text: "Paleogen" },
          ],
        },
      ],
    },
    {
      id: 4,
      img: "/zdjecie.png",
      text: "Mapa Polski w 1928 r.",
      questions: [
        {
          type: "open",
          id: 5,
          questionText:
            "Podaj wysokość najwyższego punktu Polski (z dokładnością do pełnych metrów, toleracja +- 2m)",
          additionalText: "m. n. p. m.",
        },
        {
          type: "open",
          id: 6,
          questionText:
            "Podaj wysokość najwyższego szczytu Polski (z dokładnością do pełnych metrów, toleracja +- 2m)",
          prevAnswer: "",
          postAnswer: "m. n. p. m.",
        },
      ],
    },
  ],
};

export const artifactsTypes = [
  { name: "shirt", text: "Koszulki" },
  { name: "medal", text: "Medale" },
  { name: "poster", text: "Plakaty" },
  { name: "pin", text: "Przypinki" },
  { name: "other", text: "Inne" },
];

export const olympiadEditionList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50,
];

export const tableTypes = {
  firstLevel: [
    { id: 1, name: "scores", description: "Wyniki" },
    { id: 2, name: "indexes", description: "Osoby" },
    { id: 3, name: "schools", description: "Szkoły" },
    { id: 4, name: "organizers", description: "Organizatorzy" },
    {
      id: 5,
      name: "multiples",
      description: "Uczniowie wielokrotnie zdobywający tytuły ",
    },
    {
      id: 6,
      name: "students-teachers",
      description: "Olimpijczycy nauczycielami Olimpijczyków",
    },
    {
      id: 7,
      name: "mottos",
      description: "Hasła Olimpiady",
    },
    {
      id: 8,
      name: "subjects",
      description: "Tematy pierwszego etapu",
    },
    {
      id: 9,
      name: "participants",
      description: "Liczba uczestników",
    },
    {
      id: 10,
      name: "hosts",
      description: "Gospodarze zawodów finałowych",
    },
  ],

  secondLevelScores: [
    { id: 1, name: "I-OG", description: "I OG" },
    { id: 2, name: "II-OG", description: "II OG" },
    { id: 3, name: "III-OG", description: "III OG" },
    { id: 4, name: "IV-OG", description: "IV OG" },
  ],
  secondLevelIndexes: [
    { id: 1, name: "search", description: "Szukaj osoby" },
    { id: 2, name: "teachers", description: "Nauczyciele" },
    { id: 3, name: "students", description: "Uczniowie" },
    { id: 4, name: "organizers", description: "Działacze" },
  ],
  secondLevelSchools: [
    { id: 1, name: "search", description: "Szukaj szkoły" },
    { id: 2, name: "titleNumber", description: "Liczba tytułów" },
    { id: 3, name: "diplomas", description: "Dyplomy dla szkół" },
  ],
  secondLevelOrganizers: [
    { id: 1, name: "head", description: "Komitet Główny" },
    { id: 2, name: "gdansk", description: "KO Gdańsk" },
    {
      id: 3,
      name: "awards",
      description: "Osoby wyróżnione Odznaką im. Pani Profesor Anny Dylikowej",
    },
  ],
};

export const scoresTable = {
  isWithNumeration: true,
  header: [
    {
      level: 1,
      items: [
        { title: "", colspan: 4 },
        { title: "Szkoła", colspan: 2 },
        { title: "Nauczyciel", colspan: 2 },
      ],
    },
    {
      level: 0,
      items: [
        { title: "Tytuł", prop: "title" },
        { title: "Imię", prop: "studentName" },
        { title: "Nazwisko", prop: "studentSurname" },
        { title: "Klasa", prop: "studentClass" },
        { title: "Nazwa", prop: "schoolName" },
        { title: "Miejscowość", prop: "schoolCity" },
        { title: "Imię", prop: "teacherName" },
        { title: "Nazwisko", prop: "teacherSurname" },
      ],
    },
  ],

  data: [
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      studentClass: "IV",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      schoolCity: "Wrocław",
      studentClass: "IV",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      schoolCity: "Wrocław",
      studentClass: "IV",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      schoolCity: "Wrocław",
      studentClass: "IV",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      studentClass: "IV",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      studentClass: "IV",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      studentClass: "IV",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: true,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      studentClass: "IV",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      studentClass: "IV",
      schoolCity: "Wrocław",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
    {
      _isSeparator: false,
      title: "L-1",
      studentName: "Waldemar",
      studentSurname: "Sroka",
      schoolName: "V LO",
      schoolCity: "Wrocław",
      studentClass: "IV",
      teacherName: "Jorthanis",
      teacherSurname: "Lazopoulos",
    },
  ],
};

export const studentIndexes = {
  isWithNumeration: false,
  header: [
    {
      level: 0,
      items: [
        { title: "Uczestnik", prop: "name" },
        { title: "Olimpiada", prop: "editions" },
      ],
    },
  ],
  data: [
    { _isSeparator: true, text: "N" },
    { _isSeparator: false, name: "Nowak Ewa", editions: "24, 25, 26" },
    { _isSeparator: false, name: "Nulec Ewa", editions: "46" },
    { _isSeparator: true, text: "O" },
    { _isSeparator: false, name: "Ogier Ewa", editions: "21" },
    { _isSeparator: false, name: "Olecka Ewa", editions: "1, 2, 3" },
    { _isSeparator: true, text: "T" },
    { _isSeparator: false, name: "Talec Ewa", editions: "2" },
    { _isSeparator: false, name: "Tolec Ewa", editions: "34" },
    { _isSeparator: false, name: "Tylec Ewa", editions: "10" },
  ],
};

export const serachResult = {
  person: {
    title: "",
    name: "Jan",
    surname: "Kowalski",
  },

  student: {
    laureateAchievments: [
      {
        olympiad: {
          number: "I",
          schoolYear: "1974/1975",
          hostCity: "Łódź",
        },
        school: {
          id: 15,
          name: "I LO",
          city: "Gniezno",
        },
        position: 13,
      },
    ],

    finalistAchievments: [
      {
        olympiad: {
          number: "II",
          schoolYear: "1975/1976",
          hostCity: "Łódź",
        },
        school: {
          id: 15,
          name: "I LO",
          city: "Gniezno",
        },
        position: 78,
      },
      {
        olympiad: {
          number: "III",
          schoolYear: "1976/1978",
          hostCity: "Łódź",
        },
        school: {
          id: 15,
          name: "I LO",
          city: "Gniezno",
        },
        position: 56,
      },
    ],

    interationalOlympiad: [
      {
        olympiad: {
          type: "iGEO",
          edition: 15,
          year: "2020",
          city: "Singapoure",
        },
        medal: "gold",
        place: 10,
      },
    ],
  },
};
