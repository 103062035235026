import { ToolBar } from "../components/Toolbar";
import { Box, Typography } from "@mui/material";
import PageHeader from "../components/PageHeader";
import { useEffect, useState } from "react";
import CompetitionsOfTheWeek from "../components/OlympiadsOfTheWeek";
import SelectionOfCompetitions from "../components/SelectionOfOlympiads";
import SelectionOfArtifacts from "../components/SelectionOfArtifacts";
import Animation2 from "../components/Animation2";
import Loader from "../components/Loader";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@emotion/react";
import { path } from "../consts";
import axios from "axios";
import axiosRetry from "axios-retry";
import Footer from "../components/Footer";

axiosRetry(axios, { retries: 3 });

function HomePage() {
  const [startPageContent, setStartPageContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${path}/competition/1001/get-home-page-data`)
        .then((res) => {
          setStartPageContent(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="App">
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: { bmd: 10, xs: 7 }, px: 6 }}
          >
            WIRTUALNE MUZEUM OLIMPIADY GEOGRAFICZNEJ
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 2, ml: { xs: 2, md: 15 }, mr: { xs: 2, md: 15 } }}
          >
            Witamy serdecznie w wirtualnym muzeum Olimpiady Geograficznej. Przez
            50 lat swojego istnienia zawody Olimpiady dostarczyły wielu
            niezapomnianych wspomnień i pozostawiły po sobie wiele pamiątek.
            Zapraszamy do wirtualnej podróży w przeszłość olimpijskich zmagań!
          </Typography>
          <Animation2
            component={
              <CompetitionsOfTheWeek
                competitionsOfTheWeek={startPageContent.newest_competitions}
              />
            }
          />
          <Animation2
            component={
              <SelectionOfCompetitions
                selectedCompetitions={startPageContent.selected_competitions}
              />
            }
          />

          <Animation2
            component={
              <SelectionOfArtifacts
                selectedArtifacts={startPageContent.selected_artifacts}
              />
            }
          />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default HomePage;
