import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useState } from "react";


export default function AnimatedCard(props) {
    const navigate = useNavigate();
    const [wasReleased, setWasReleased] = useState(false);

    return (
        <Box
        sx={{
            mx: 3,
            p: 3,
            my: 2,
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
            "@keyframes color-change": {
            "0%": {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
                mx: 3,
            },
            "100%": {
                backgroundColor: "#c9d6f0",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
                mx: 1,
            },
            },
            "@keyframes color-change-reverse": {
            "0%": {
                backgroundColor: "#c9d6f0",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.4)",
                mx: 1,
            },
            "100%": {
                backgroundColor: "#ffffff",
                boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.15)",
                mx: 3,
            },
            },
            "&:hover": {
            backgroundColor: {md: "#c9d6f0", xs: '#ffffff'},
            boxShadow: {md: "0px 0px 30px rgba(0, 0, 0, 0.4)", xs: "0px 0px 30px rgba(0, 0, 0, 0.15)"},
            mx: {md: 1, xs: 3},
            animation: {md: "color-change 0.6s ease", xs: null}
            },
            borderRadius: "20px",
            animation: {md: wasReleased ? "color-change-reverse 0.6s ease" : null, xs: null}
        }}
        onMouseLeave ={()=> setWasReleased(true)}
        onClick={() => {
            navigate(`${props.path}`);
            window.scrollTo(0, 0);
        }}
        >
        <Typography>
            <b>{props.name}</b>
        </Typography>
        <Box
            sx={{
            mt: 1,
            width: "90%",
            borderRadius: "10px",
            maxHeight: "300px",
            objectFit: "cover",
            objectPosition: "top",
            }}
            component="img"
            src={props.image}
        />
        </Box>) 
}