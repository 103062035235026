import { ToolBar } from "../components/Toolbar";
import OlympiadCard from "../components/OlympiadCard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PageHeader from "../components/PageHeader";
import { useEffect, useState } from "react";
import Animation from "../components/Animation";
import { toRomanNumber } from "../consts";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { path } from "../consts";
import axios from "axios";
import Loader from "../components/Loader";
import axiosRetry from 'axios-retry';
import Footer from "../components/Footer";
import EditionMap from "../components/EditionMap";

axiosRetry(axios, { retries: 3 });

function OlympiadList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const [alignment, setAlignment] = useState("cards");
  const [competitionList, setCompetitionList] = useState(null);
  const [fullCompetitionList, setFullCompetitionList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [serachPhrase, setSearchPhrase] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${path}/competition/1001`)
        .then((res) => {
          setCompetitionList(res.data);
          setFullCompetitionList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };

  useEffect(() => {
    if (!serachPhrase) setCompetitionList(fullCompetitionList);
    else {
      const delayDebounceFn = setTimeout(() => {
        axios
          .get(`${path}/competition/1001/search/${serachPhrase}`)
          .then((res) => {
            setCompetitionList(res.data);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [serachPhrase, fullCompetitionList]);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: {bmd: 10, xs: 7} }}
          >
            Edycje Olimpiady Geograficznej
          </Typography>
          <Grid container sx={{ p: { md: 5, xs: 3 } }} spacing={2}>
            <Grid item xs={12} md={2.5}>
              {" "}
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="cards">Widok kart</ToggleButton>
                <ToggleButton value="list">Widok listy</ToggleButton>
                <ToggleButton value="map">Widok mapy</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={10} md={7.5}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Wyszukaj"
                variant="outlined"
                onChange={(e) => {
                  setSearchPhrase(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Wyszukiwanie możliwe jest po numerze olimpiady (cyfry rzymskie), miejscowości finałów, nazwisku uczestnika">
                <IconButton>
                  <HelpOutlineIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {alignment === "cards" && (
            <Grid container sx={{p: 3}}>
              {competitionList.map((competition) => (
                <Grid item xs={12} md={6} bmd={4} lg={3} key={competition.id}>
                  <Animation
                    component={
                      <OlympiadCard
                        photo={competition.main_image}
                        editionNumber={
                          toRomanNumber[competition.edition_number]
                        }
                        hostCity={competition.host_cities[0]}
                        schoolYear={competition.school_year}
                        id={competition.id}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {alignment === "list" && (
            <div>
              {competitionList.map((competition) => (
                <Box sx={{ ml: 3, mt: 4, display:'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center' }}>
                  <Box sx={{mr: 10}}>
                      <Typography variant="h6">
                        {toRomanNumber[competition.edition_number]} Olimpiada
                        Geograficzna
                      </Typography>
                      <Typography>
                        {competition.school_year}, {competition.host_cities[0]}
                      </Typography>
                  </Box>
                      <Button
                        onClick={() => {
                          navigate(`/olimpiada/${competition.id}`);
                          window.scrollTo(0, 0);
                        }}
                      >
                        Dowiedz się więcej...
                      </Button>
                </Box>
              ))}
            </div>
          )}
          {alignment === "map" && (
            <div>

                <Box sx={{ ml: 3, mt: 1, display:'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                  <EditionMap height='800px'/>
                </Box>
              
            </div>
          )}
          <Footer/>
        </div>
      )}

    </div>
  );
}

export default OlympiadList;
