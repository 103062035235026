import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  FormControl,
  Grid,
  TextField,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import PageHeader from "../components/PageHeader";
import { ToolBar } from "../components/Toolbar";
import { useTheme } from "@emotion/react";
import "./MapStats.css";
import Footer from "../components/Footer";
import axios from "axios";
import { path, toArabicNumber, toRomanNumber } from "../consts";
import axiosRetry from "axios-retry";
import { useNavigate } from "react-router";

axiosRetry(axios, { retries: 3 });

export default function SchoolStats(props) {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("bmd"));
  const [selectedType, setSelectedType] = useState("");
  const [tableData, setTableData] = useState(null);
  const [sortingIndex, setSortingIndex] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [searchParams, setSearchParams] = useState({'number': 0, 'city': "", 'name': ""})
  const [results, setResults] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
      const fetchData = async () => {
          if(searchParams.number === 0 && searchParams.city=== '' && searchParams.name===''){
            setIsLoading(false);
            setResults(null)
          }
          else{
            if (!isNaN(searchParams.number)) {var number = searchParams.number}
            else if (toArabicNumber[searchParams.number] !== undefined) {var number = toArabicNumber[searchParams.number]}
            else {var number = 0}
            axios
              .get(`${path}/search/school/?city=${searchParams.city}&number=${number}&name=${searchParams.name}`)
              .then((res) => {
                console.log(res.data.results)
                setResults(res.data.results);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log(err.message);
              });
          }
        };
      fetchData()
    }, [searchParams]);

  const statisticsOptions = [
    {
      name: "Szukaj szkoły",
      id: 1,
    },
    {
      name: "Lista szkół",
      id: 2,
    },
  ];

  async function handleChangeSecond(event) {
    let selectedType = event.target.value;
    setSelectedType(selectedType);

    if (selectedType == 2) {
      setIsLoading(true);
      const fetchData = async () => {
        axios
          .get(`${path}/statistics/schools/1001`)
          .then((res) => {
            console.log(res.data)
            setTableData(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
          });
      };
      fetchData();
    }
  }


  var headers = [
    {
      level: 0,
      items: [
       
        !isMobile ?  { title: "Nazwa szkoły", prop: "name" } : { title: "Nazwa szkoły", prop: "name_shorten" },
        { title: "Miasto", prop: "city" },
        !isMobile ? { title: "Edycje Olimpiady", prop: "editions" } : {},
        { title: "L", prop: "L_number" },
        { title: "F", prop: "F_number" },
      ],
    },
  ];

  function handleSort(sortBy) {
    if (sortingIndex !== sortBy) {
      setSortingIndex(sortBy);
      setIsAscending(true);
    } else setIsAscending((prev) => !prev);
    setTableData((prevTableData) =>
      prevTableData.toSorted((a, b) => {
        let first_element = !isNaN(parseInt(String(a[sortBy]).split(",")[0]))
          ? parseInt(String(a[sortBy]).split(",")[0])
          : a[sortBy];
        let second_element = !isNaN(parseInt(String(b[sortBy]).split(",")[0]))
          ? parseInt(String(b[sortBy]).split(",")[0])
          : b[sortBy];
        return isAscending
          ? first_element > second_element
          : first_element < second_element;
      })
    );
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Box sx={{ position: "sticky", top: 0, zIndex: 99999 }}>
            <ToolBar></ToolBar>
          </Box>
          <PageHeader />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={isMobile ? "h4" : "h2"}
            sx={{ mt: { bmd: 10, xs: 7 } }}
          >
            Kącik Statystyczny
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            variant={"h5"}
            sx={{ mt: 2, mb: 5 }}
          >
            Olimpisjkie szkoły
          </Typography>
          <Box
            margin='auto'
            maxWidth="300px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={3}
          >
            <FormControl fullWidth>
              <InputLabel id="select-label">Rodzaj statystyk</InputLabel>
              <Select
                labelId="select-label"
                value={selectedType}
                label="Rodzaj statystyk"
                onChange={handleChangeSecond}
              >
                {statisticsOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {selectedType === 1 &&
            <Grid container  
            spacing={{xs: 1,  md: 3}}            
            sx={{
              ml: 4,
              mr: 4,
              mt: 5,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}>
              <Grid item xs={0} md={2}>
              </Grid>
              <Grid item xs={2} md={2}>
                <TextField label='Miasto' sx={{width: '100%'}}               
                onChange={(e) => {
                  setSearchParams({...searchParams, city: e.target.value});
                }}/>
              </Grid>
              <Grid item  xs={2} md={2}>
                <TextField label='Numer szkoły'sx={{width: '100%'}}                
                onChange={(e) => {
                  if (e.target.value == '')  {setSearchParams({...searchParams, number: 0})}
                  else {setSearchParams({...searchParams, number: e.target.value})};
                }}/>
              </Grid>
              <Grid item xs={4} md={4}>
                <TextField label='Nazwa szkoły'sx={{width: '100%'}}         
                onChange={(e) => {
                  setSearchParams({...searchParams, name: e.target.value});
                }}/>
              </Grid>
              <Grid item xs={0} md={2}>
              </Grid>
              <Grid item xs={12} sx={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              {results && results.length !== 0 &&
                <List sx={{width: '50%',  bgcolor: 'background.paper',  boxShadow: '0px 2px 5px grey',  borderRadius: '0 0 5px 5px', p:0}}>
                  {results.map(school => 
                  <>
                    <ListItem className="search-result" sx={{py: 2}}
                    onClick={() => navigate(`./${school.SchoolVersion.school_id}`)}>
                      {school.SchoolVersion.name} ({school.city})
                      
                    </ListItem>
                    <Divider/>
                    </>
                  )}
                </List>}
                </Grid>
                </Grid>
          }
          {selectedType === 2 && tableData && (
            <Box
              sx={{
                ml: 4,
                mr: 4,
                mt: 2,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <table className="styled-table">
                <thead>
                  {headers.map((level) => {
                    return (
                      <tr>
                        {level.items.map((column) => (
                          <th onClick={() => handleSort(column.prop)}>
                            {column.title}
                          </th>
                        ))}
                      </tr>
                    );
                  })}
                </thead>
                <tbody>
                  {tableData.map((item, index) => {
                    return (
                      <tr className={"expanded"}>
                        {headers[headers.length - 1].items.map((column) => (
                          <th style={{cursor: 'pointer'}} onClick={() => navigate(`./${item.id}`)}>{item[column.prop]}</th>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          )}
          <Footer />
        </div>
      )}
    </div>
  );
}
